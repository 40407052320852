export const postWhatsAppTrack = async (payload) => {
  let timeStamp = new Date();

  let date =
    timeStamp?.getDate() +
    "/" +
    timeStamp?.getMonth() +
    "/" +
    timeStamp?.getFullYear();
  let utm_info = localStorage?.getItem("urlParams") ?? "organic";

  const WEBAPP_URL = `https://script.google.com/macros/s/${process.env.NEXT_PUBLIC_WHATSAPP_TRACK}/exec?date=${date}&Phone=${payload?.phone}&state=${payload?.state}&UTM=${utm_info}&Action=${"WhatsappPopup-Click"}&Timestamp=${timeStamp}`;

  await fetch(`${WEBAPP_URL}`, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    withCredentials: true,
    credentials: "same-origin",
  })
    .then((res) => {
      console.log("result", res);
    })
    .catch((error) => {
      console.log("error", error);
    });
};
