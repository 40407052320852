import { Typography } from "@material-ui/core";
import { Box, Drawer, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { StateOptions } from "../constants";
import { primary } from "../constants/theme";
import { Context } from "../context/context";
import { MemoizedOTP } from "../modules/Otp/otp";
import useWhatsappPopupForm from "../Popups/whatsAppPopup/hook/useWhatsappPopupForm";
import { MemoizedButton } from "../SDK/button";
import { MemoizedSelectDropDown } from "../SDK/selectDropdown/selectDropdown";
import { checkError } from "../SDK/utils/validationHelpers";
import { getCookie } from "../utils/cookie";
import { myScroll } from "../utils/myScroll";

const WhatsAppPopupDialog = styled(Box)(({ theme }) => ({
  //   height: "400px",
  marginTop: "40px",
  marginBottom: "40px",
  padding: "1em",
  ".input-box": {
    //marginBottom: "10px",
    //marginTop: "20px",

    width: "100%",
  },
  ".select-box": {
    margin: "10px",
    marginTop: "20px",
    width: "96%",
  },
  ".button-lets-chat": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".heading": {
    fontWeight: "700",
    textAlign: "center",
    fontSize: "2em",
  },
  ".text-contact-soon": {
    textAlign: "center",
    fontSize: "1.5em",
    marginBottom: "0.5em",
  },
  ".receive-notifications": {
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "3em",
    marginRight: "3em",
  },
  ".terms-and-conditions": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    maxWidth: "542px",
    color: primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  ".check-label": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "400",
    fontSize: "12px",
  },
  ".otp-disclaimer-text": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "700",
    color: "#666666",
    fontSize: "12px",
  },
  [theme.breakpoints.down("md")]: {
    ".heading": {
      fontWeight: "700",
      textAlign: "center",
      fontSize: "1em",
    },
    ".receive-notifications": {
      fontSize: "10px",
      marginBottom: "10px",
      marginLeft: "1em",
      marginRight: "1em",
    },
    ".text-contact-soon": {
      textAlign: "center",
      fontSize: "1em",
      marginBottom: "0.5em",
    },
    ".terms-and-conditions": {
      fontSize: "10px",
      lineHeight: "15px",
    },
    ".check-label": {
      fontSize: "12px",
      lineHeight: "16px",
    },
    "otp-disclaimer-text": {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#666666",
    },
    ".input-box": {
      width: "100%",
    },
    ".select-box": {
      width: "100%",
    },
  },
}));

const WhatsAppDrawer = () => {
  const state = useContext(Context);
  const { form, loading } = useWhatsappPopupForm();

  let open = state.showWhatsappPopupValue;
  const { hideWhatsappPopup } = useContext(Context);
  const [isCheckedModal, setIsCheckedModal] = useState(true);

  const handleCheckboxChangeModal = () => {
    setIsCheckedModal(!isCheckedModal);
  };
  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  useEffect(() => {
    form.initialValues.mobile = cacheInfo?.phone;
  }, [cacheInfo, form.initialValues]);

  return (
    <Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => {
          hideWhatsappPopup();
        }}
        onOpen={open}
        transitionDuration={1000}
        sx={{ display: { xs: "block", sm: "none" } }}
      >
        <WhatsAppPopupDialog>
          {state?.connect_type === "brand-page" ? (
            <Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    margin: "10px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Chat with Us
                </Typography>
              </Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    margin: "10px",
                    textAlign: "center",
                  }}
                >
                  Talk to our expert for any Queries, Service Requests
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className="heading">
              <span style={{ color: primary.main }}>Upto ₹5000</span> Discount +
              Talk to our expert
            </Box>
          )}
          <form onSubmit={form.handleSubmit} className="form-schedule">
            <Box sx={{ mb: "1em", mt: "2em" }}>
              <MemoizedOTP
                checked={isCheckedModal}
                input_type="whatsapp"
                form={form}
                className="otp-styles"
                connect_type={state.connect_type}
                type=""
              />
            </Box>
            {state?.connect_type !== "brand-page" && (
              <Box sx={{ mb: "1em" }}>
                <MemoizedSelectDropDown
                  form={form}
                  id={"state"}
                  name="state"
                  required={true}
                  title={"Select State"}
                  value={form.values.state}
                  optionsData={StateOptions}
                  onChange={form.handleChange}
                  error={!!checkError("state", form)}
                  helperText={form.errors.state}
                />
              </Box>
            )}
            <Box className="text-contact-soon">We will contact you soon!</Box>

            <Box>
              {!state.showOtpModule ? (
                <Box
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "baseline",
                  }}
                  sx={{ mt: { xs: "24px", md: "24px" } }}
                >
                  <input
                    label="whatsapp-checkbox"
                    type="checkbox"
                    required={true}
                    checked={isCheckedModal}
                    onChange={handleCheckboxChangeModal}
                  />

                  <Typography className="check-label">
                    I agree to the terms and conditions below and give my
                    consent to receive calls and messages to provide further
                    information.
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography className="otp-disclaimer-text">
                    OTP Verification enables us to secure your account and
                    enroll you into our promotional programs{" "}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box className="button-lets-chat" sx={{ mb: "1em", mt: "1em" }}>
              <MemoizedButton
                id="lead-form"
                disabled={!isCheckedModal}
                type="submit"
                content={"Let's Chat"}
                sx={{
                  alignItems: "center",
                  background: "#35BF68",
                  boxShadow: "0px 4px 0px #177138",
                }}
                loading={loading}
                handleClick={form.handleSubmit}
              />
            </Box>
            <Typography
              className="terms-and-conditions"
              onClick={() => {
                // window?.scrollTo &&
                //   window?.scrollTo({
                //     top: document.getElementById("footer").offsetTop - 100,
                //     behavior: "smooth",
                //   });
                myScroll(
                  0,
                  document.getElementById("footer").offsetTop - 100,
                  "smooth"
                );
              }}
            >
              Terms and Conditions
            </Typography>
          </form>
        </WhatsAppPopupDialog>
      </Drawer>
    </Box>
  );
};

// export default WhatsAppDrawer;

export const MemoizedWhatsAppDrawer = React.memo(WhatsAppDrawer);
