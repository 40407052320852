import { Typography } from "@material-ui/core";
import { Box, styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import React, { useContext, useEffect, useState } from "react";

import { StateOptions } from "../../constants";
import { primary } from "../../constants/theme";
import { Context } from "../../context/context";
import { MemoizedOTP } from "../../modules/Otp/otp";
import { MemoizedButton } from "../../SDK/button";
import { MemoizedSelectDropDown } from "../../SDK/selectDropdown/selectDropdown";
import { checkError } from "../../SDK/utils/validationHelpers";
import { getCookie } from "../../utils/cookie";
import useWhatsappPopupForm from "./hook/useWhatsappPopupForm";

const WhatsAppPopupDialog = styled(Box)(({ theme }) => ({
  ".input-box": {
    margin: "10px",
    marginTop: "20px",
    width: "96%",
  },
  ".select-box": {
    margin: "10px",
    marginTop: "20px",
    width: "96%",
  },
  ".button-lets-chat": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".heading": {
    fontWeight: "700",
    textAlign: "center",
    fontSize: "2em",
  },
  ".text-contact-soon": {
    textAlign: "center",
    fontSize: "1.5em",
    marginBottom: "0.5em",
  },
  ".receive-notifications": {
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "3em",
    marginRight: "3em",
  },
  ".terms-and-conditions": {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    textAlign: "center",
    marginTop: "10px",
    maxWidth: "542px",
    color: primary.main,
    textDecoration: "underline",
    cursor: "pointer",
  },
  ".check-label": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "400",
    fontSize: "12px",
  },
  ".otp-disclaimer-text": {
    width: "100%",
    maxWidth: "500px",
    fontWeight: "700",
    color: "#666666",
    fontSize: "12px",
  },
  [theme.breakpoints.down("md")]: {
    ".MuiDialog-root MuiModal-root css-1msmp8t-MuiModal-root-MuiDialog-root": {
      display: "none !important",
    },
    // ".MuiDrawer-root MuiDrawer-modal MuiModal-root MuiModal-hidden css-11qg6p-MuiModal-root-MuiDrawer-root":
    //   {
    //     display: "none",
    //   },
    ".css-1msmp8t-MuiModal-root-MuiDialog-root": {
      display: "none !important",
    },
    ".heading": {
      fontWeight: "700",
      textAlign: "center",
      fontSize: "1em",
    },
    ".receive-notifications": {
      fontSize: "10px",
      marginBottom: "10px",
      marginLeft: "1em",
      marginRight: "1em",
    },
    ".text-contact-soon": {
      textAlign: "center",
      fontSize: "1em",
      marginBottom: "0.5em",
    },
    ".terms-and-conditions": {
      fontSize: "10px",
      lineHeight: "15px",
    },
    ".check-label": { fontSize: "12px", linHeight: "16px", fontSize: "10px" },
    "otp-disclaimer-text": {
      fontSize: "12px",
      linHeight: "16px",
      color: "#666666",
    },
    ".input-box": {
      width: "92%",
    },
    ".select-box": {
      width: "92%",
    },
  },
}));

const WhatsAppPopup = () => {
  const state = useContext(Context);
  const { form, loading } = useWhatsappPopupForm();
  const [isCheckedModal, setIsCheckedModal] = useState(true);

  let open = state.showWhatsappPopupValue;
  const { hideWhatsappPopup } = useContext(Context);

  const handleCheckboxChangeModal = () => {
    setIsCheckedModal(!isCheckedModal);
  };
  let cacheInfo = getCookie("user_verified")
    ? JSON.parse(getCookie("user_verified"))
    : "";

  useEffect(() => {
    form.initialValues.mobile = cacheInfo?.phone;
  }, [cacheInfo, form.initialValues]);

  return (
    // <WhatsAppPopupDialog>
    <Box>
      <Dialog
        // PaperProps={{
        //   sx: { height: "100%", display: { xs: "none", sm: "block" } },
        // }}
        sx={{ height: "100%" }}
        open={open}
        onClose={() => {
          hideWhatsappPopup();
        }}
        // PaperProps={
        //   sx: { display: { xs: "block", sm: "none" } }}
        // }
      >
        <DialogContent
          sx={{ margin: "5px", display: { xs: "none", sm: "block" } }}
        >
          <WhatsAppPopupDialog>
            <Box className="heading">
              <span style={{ color: primary.main }}>Upto ₹5000</span> Discount +
              Free EV Consultation!
            </Box>

            <form onSubmit={form.handleSubmit} className="form-schedule">
              <Box>
                <MemoizedOTP
                  checked={isCheckedModal}
                  input_type="whatsapp"
                  form={form}
                  className="otp-styles"
                  connect_type={state.connect_type}
                  type=""
                />
              </Box>
              <Box className="select-box">
                <MemoizedSelectDropDown
                  form={form}
                  id={"state"}
                  name="state"
                  required={true}
                  title={"Select State"}
                  value={form.values.state}
                  optionsData={StateOptions}
                  onChange={form.handleChange}
                  error={!!checkError("state", form)}
                  helperText={form.errors.state}
                />
              </Box>
              <Box className="text-contact-soon">We will contact you soon!</Box>

              <Box>
                {!state.showOtpModule ? (
                  <Box
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "baseline",
                    }}
                    sx={{ mt: { xs: "24px", md: "24px" } }}
                  >
                    <input
                      label="whatsappPopup-checkbox"
                      type="checkbox"
                      required={true}
                      checked={isCheckedModal}
                      onChange={handleCheckboxChangeModal}
                    />

                    <Typography className="check-label">
                      I agree to the terms and conditions below and give my
                      consent to receive calls and messages to provide further
                      information.
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Typography className="otp-disclaimer-text">
                      OTP Verification enables us to secure your account and
                      enrol you into our promotional programs
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box className="button-lets-chat">
                <MemoizedButton
                  id="lead-form"
                  disabled={!isCheckedModal}
                  type="submit"
                  content={"Let's Chat"}
                  sx={{
                    alignItems: "center",
                    background: "#35BF68",
                    boxShadow: "0px 4px 0px #177138",
                  }}
                  loading={loading}
                  handleClick={form.handleSubmit}
                />
              </Box>
              <Typography
                className="terms-and-conditions"
                onClick={() => {
                  window.scrollTo &&
                    window.scrollTo({
                      top: document.getElementById("footer").offsetTop - 100,
                      behavior: "smooth",
                    });
                }}
              >
                Terms and Conditions
              </Typography>
            </form>
          </WhatsAppPopupDialog>
        </DialogContent>
      </Dialog>
    </Box>
    // </WhatsAppPopupDialog>
  );
};

export default WhatsAppPopup;
export const MemoizedWhatsAppPopup = React.memo(WhatsAppPopup);
