import { MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import { mapStates } from "../../constants/mapRegions";
import { primary } from "../../constants/theme";
import { getLocationInfo } from "../../services/locationService";
import { getSelectOptions } from "../utils/inputHelper";
import { checkError } from "../utils/validationHelpers";

const SelectDropDown = ({
  form, //formik form
  id, //state or vehicle
  labelId, //label id(Select vehicle)
  name,
  onChange,
  required, //true or false
  value,
  title, // placeholder
  optionsData, // options for dropdown
  helperText,
  ...rest
}) => {
  const [geoState, setGeoState] = useState("none");

  useEffect(() => {
    getLocationInfo()
      .then((res) => {
        setGeoState(mapStates[res?.data?.countryRegion] ?? "none");

        if (mapStates[res?.data?.countryRegion]) {
          form.initialValues.state = mapStates[res?.data?.countryRegion];
        }
      })
      .catch((error) => {
        setGeoState("none");
        console.log("error", error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      // error={!!checkError(name, form)}
      labelId={labelId}
      id={id}
      name={name}
      value={value === "none" ? geoState : value}
      defaultValue={"none"}
      onChange={onChange}
      required={required}
      fullWidth={true}
      sx={{
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: primary.mainGreen,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: primary.mainGreen,
        },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            "& .MuiMenuItem-root": {
              maxHeight: "fix-content",
            },
          },
        },
      }}
      {...rest}
    >
      <MenuItem value={"none"} disabled={true} sx={{ fontWeight: 600 }}>
        {title}
      </MenuItem>
      {getSelectOptions(optionsData)}
    </Select>
  );
};

export const MemoizedSelectDropDown = React.memo(SelectDropDown);
